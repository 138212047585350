import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from 'react-icons/md';
import { setDefaults, setPage } from '../../app/slices/pagination.slice';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Pagination = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { totalPages, totalResults, limit, page } = useSelector(
    (state) => state.paginate,
  );

  const handlePageClick = ({ selected }) => {
    dispatch(setPage(selected + 1));
  };

  useEffect(() => {
    dispatch(setDefaults());
  }, [pathname]);

  return (
    <>
      {totalResults > limit && (
        <ReactPaginate
          className="d-flex justify-content-center mt-3 pagination"
          previousClassName="paginate-arrows"
          nextClassName="paginate-arrows"
          previousLinkClassName="paginate-link"
          nextLinkClassName="paginate-link"
          pageClassName="paginate-page"
          activeLinkClassName="paginate-link"
          activeClassName="active-page"
          breakLabel="..."
          disabledLinkClassName="disabled-arrows"
          disableInitialCallback={true}
          nextLabel={<MdOutlineKeyboardDoubleArrowRight />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={totalPages}
          previousLabel={<MdOutlineKeyboardDoubleArrowLeft />}
          renderOnZeroPageCount={null}
          forcePage={page - 1}
        />
      )}
    </>
  );
};

export default Pagination;
