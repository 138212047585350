import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api_url } from '../../constants/envVars';

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),
  tagTypes: ['Locations', 'Routes'],

  endpoints: ({ query, mutation }) => ({
    getLocations: query({
      query: ({ page, limit, search }) => ({
        url: `/location?page=${page}&limit=${limit}&search=${search}`,
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Locations'],
    }),
    getAllLocations: query({
      query: () => ({
        url: 'all-location',
      }),
    }),
    addLocations: mutation({
      query: (data) => ({
        url: '/location',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Locations'],
    }),
    deleteLocation: mutation({
      query: ({ id }) => ({
        url: `/location/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Locations'],
    }),
    getRoutes: query({
      query: ({ page, limit, search }) => {
        return {
          url: `/route?page=${page}&limit=${limit}&search=${search}`,
        };
      },
      providesTags: ['Routes'],
    }),
    createRoutes: mutation({
      query: (data) => ({
        url: '/route',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Routes'],
    }),
    updateRoute: mutation({
      query: (data) => ({
        url: '/route',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Routes'],
    }),
    deleteRoute: mutation({
      query: ({ id }) => ({
        url: `/route/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Routes'],
    }),
    fetchLocationsForSelect: query({
      query: ({ type, query }) => `/locations/${type}?query=${query}`,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useAddLocationsMutation,
  useDeleteLocationMutation,
  useGetAllLocationsQuery,
  useGetRoutesQuery,
  useCreateRoutesMutation,
  useUpdateRouteMutation,
  useDeleteRouteMutation,
  useFetchLocationsForSelectQuery,
} = locationApi;
