import React from 'react';
import { Button } from 'react-bootstrap';

const SubmitButton = (props) => {
  return (
    <Button type="submit" className="w-75 login-btn" {...props}>
      {props.children}
    </Button>
  );
};

export default SubmitButton;
