import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import InputField from '../../components/inputs/InputField';
import { IoCloudUploadSharp } from 'react-icons/io5';
import { useUpdateDriverMutation } from '../../app/services/user.service';
import useToast from '../../hooks/useToast';
const DriverProfile = ({ data }) => {
  const [updateDriver] = useUpdateDriverMutation();
  const [img, setImg] = useState();
  const [prevImg, setPrevImg] = useState();
  const makeToast = useToast();

  const initialValues = {
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    email: data?.email ?? '',
    uniqueId: data?.uniqueId ?? '',
    phone: data?.phone ?? '',
    mongolianRegNumber: data?.mongolianRegNumber ?? '',
    bankName: data?.driverInfo?.bankName ?? '',
    accountName: data?.driverInfo?.accountName ?? '',
    accountNumber: data?.driverInfo?.accountNumber ?? '',
  };

  const handleImgUpload = (e) => {
    if (e.target.files[0]) {
      setImg(e.target.files[0]);
      setPrevImg(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <div className="container">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required*';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }

          if (!values.firstName) {
            errors.firstName = 'Required*';
          }
          if (!values.lastName) {
            errors.lastName = 'Required*';
          }
          if (!values.uniqueId) {
            errors.uniqueId = 'Required*';
          } else if (String(values?.uniqueId).length > 4) {
            console.log(values?.uniqueId.length);
            errors.uniqueId = 'Max 4 characters allowed';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const formdata = new FormData();

          if (img) {
            formdata.append('profilePic', img);
          }
          if (values?.email !== data?.email) {
            formdata.append('email', values?.email);
          }

          formdata.append('id', data?._id);
          [
            'firstName',
            'lastName',
            'uniqueId',
            // "email",
            'phone',
            'mongolianRegNumber',
          ]?.forEach((key) => formdata.append(key, values[key]));

          const response = await updateDriver(formdata);
          if (response?.error) makeToast(response?.error?.data);
          if (response?.data) makeToast(response?.data);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="row mt-4">
              <div className="col-md-9">
                <div className="input-details p-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <label>First Name</label>
                        <ErrorMessage
                          className="formik-errors"
                          name="firstName"
                          component="span"
                        />
                      </div>
                      <InputField type="text" name="firstName" />
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <label>Last Name</label>
                        <ErrorMessage
                          className="formik-errors"
                          name="lastName"
                          component="span"
                        />
                      </div>
                      <InputField type="text" name="lastName" />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <label>Email Address</label>
                        <ErrorMessage
                          className="formik-errors"
                          name="email"
                          component="span"
                        />
                      </div>
                      <InputField type="text" name="email" />
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <label>Trip Code</label>
                        <ErrorMessage
                          className="formik-errors"
                          name="uniqueId"
                          component="span"
                        />
                      </div>
                      <InputField type="number" name="uniqueId" />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <label>Phone Number</label>
                        <ErrorMessage
                          className="formik-errors"
                          name="phone"
                          component="span"
                        />
                      </div>
                      <InputField type="number" name="phone" />
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <label>Mongolian Registration No.</label>
                        <ErrorMessage
                          className="formik-errors"
                          name="mongolianRegNumber"
                          component="span"
                        />
                      </div>
                      <InputField type="text" name="mongolianRegNumber" />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <label>Bank Name</label>
                        <ErrorMessage
                          className="formik-errors"
                          name="bankName"
                          component="span"
                        />
                      </div>
                      <InputField type="text" name="bankName" disabled />
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <label>Account Name</label>
                        <ErrorMessage
                          className="formik-errors"
                          name="accountName"
                          component="span"
                        />
                      </div>
                      <InputField type="text" name="accountName" disabled />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <label>Account Number</label>
                        <ErrorMessage
                          className="formik-errors"
                          name="accountNumber"
                          component="span"
                        />
                      </div>
                      <InputField type="number" name="accountNumber" disabled />
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content-center">
                  <div className="driver-pp">
                    <img src={prevImg ?? data?.driverInfo?.profilePic} />
                  </div>
                  <div className="wrapper">
                    <input
                      onChange={handleImgUpload}
                      type="file"
                      className="input-cloud"
                    />
                    <IoCloudUploadSharp className="cloud-img" />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <img
                      className="dl-img"
                      src={
                        data?.driverInfo.driverLicense[0] ??
                        require('../../assets/images/dl.jpg')
                      }
                    />
                  </div>
                  <div>
                    <img
                      className="dl-img"
                      src={
                        data?.driverInfo.driverLicense[1] ??
                        require('../../assets/images/dl.jpg')
                      }
                    />
                  </div>
                </div>
                <div className="w-100 align-bottom d-flex justify-content-center mt-5">
                  <button className="update-driver-btn align-text-bottom">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DriverProfile;
