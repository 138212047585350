import React from 'react';
import ReactDOM from 'react-dom/client';
// import "./index.css";
import 'react-quill/dist/quill.core.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/styles.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/lara-light-green/theme.css'; // Default theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PrimeReactProvider value={{inputStyle:'filled',ripple: true}}>
        <App />
      </PrimeReactProvider>
    </Provider>
  </React.StrictMode>,
);
