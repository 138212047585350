import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from './EditorToolbar';

const Editor = ({ value, setValue }) => {
  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        placeholder={'Type Here...'}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
