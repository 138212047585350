import React, { useState } from 'react';
import { useGetTripsQuery } from '../../app/services/trip.service';
import { useSelector } from 'react-redux';
import usePageChange from '../../hooks/usePageChange';
import { GoArrowRight } from 'react-icons/go';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import TripDetailsModal from '../../components/modals/TripDetailsModal';
import { IoSnow } from 'react-icons/io5';
import { MdEdit, MdSunny } from 'react-icons/md';
import CommonModal from '../../components/modals/CommonModal';

const TripsTable = () => {
  const { page, limit, search } = useSelector((state) => state.paginate);

  const { data } = useGetTripsQuery({ page, limit, type: 'all', search });

  const [show, setShow] = useState(false);
  const [commonShow, setCommonShow] = useState(false);
  const trips = data?.data?.result;
  const paginate = data?.data?.paginate;
  const [modalData, setModalData] = useState();

  usePageChange(paginate);

  const handleTripDetails = (trip) => {
    setModalData(trip);
    setShow(true);
  };

  const handleFreezeUnfreeze = (trip) => {
    setModalData(trip);
    setCommonShow(true);
  };

  return (
    <>
      {trips?.length ? (
        trips?.map((trip, i) => {
          // i === 0 && console.log(booking);
          return (
            <tr key={i} className="align-middle">
              <th scope="row">{i + 1}</th>
              <td>{'#' + trip?.tripNo}</td>
              <td>
                {`${moment(trip?.tripDate).format('DD/MM/YY')}
              (${moment(trip?.departureTime).format('hh:mm a')})`}
              </td>
              <td>{trip?.driverId?.phone}</td>
              <td>
                <span>
                  {trip?.pickUpCity} <GoArrowRight /> {trip?.dropOffCity}
                </span>
              </td>
              <td>{trip?.totalPassengers}</td>
              <td>{trip?.totalPrice}₮</td>
              <td>
                <div className="d-flex">
                  <div
                    onClick={() => handleFreezeUnfreeze(trip)}
                    className={`d-flex p-1 align-items-center justify-content-center icon-box ${
                      trip?.isActive === true ? 'dark-blue' : 'yellow'
                    }`}
                  >
                    {trip?.isActive === true ? (
                      <IoSnow className="action-icon" />
                    ) : (
                      <MdSunny className="action-icon" />
                    )}
                  </div>
                  <Link
                    className={trip?.totalPassengers ? 'pe-none' : ''}
                    to={`/trip/details?tripId=${trip?._id}`}
                  >
                    <div
                      className={`d-flex p-1 align-items-center justify-content-center icon-box blue ${
                        trip?.totalPassengers && 'disabled-btn'
                      }`}
                    >
                      <MdEdit className="action-icon" />
                    </div>
                  </Link>
                  <div
                    onClick={() =>
                      !!trip?.totalPassengers && handleTripDetails(trip)
                    }
                    className={`d-flex p-1 align-items-center justify-content-center icon-box blue ${
                      !trip?.totalPassengers && 'disabled-btn'
                    }`}
                  >
                    <FaEye className="action-icon" />
                  </div>
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <td className="align-middle">No entries yet</td>
      )}

      <TripDetailsModal show={show} setShow={setShow} data={modalData} />
      <CommonModal
        show={commonShow}
        type="freezeUnfreezeTrip"
        setShow={setCommonShow}
        data={modalData}
        contentParams={{ status: modalData?.isActive }}
      />
    </>
  );
};

export default TripsTable;
