import React, { useState } from 'react';
import { useGetUsersQuery } from '../app/services/user.service';
import { MdDelete } from 'react-icons/md';
import { ImUserMinus } from 'react-icons/im';
import CommonModal from '../components/modals/CommonModal';
import { Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import usePageChange from '../hooks/usePageChange';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const UserManagement = () => {
  const { page, limit, search } = useSelector((state) => state.paginate);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({
    type: '',
    data: '',
  });

  const { data } = useGetUsersQuery({ page, limit, search });

  const users = data?.data?.result;
  const paginate = data?.data?.paginate;

  usePageChange(paginate);

  const handleModal = (type, data) => {
    setShow(true);
    setModalData({ type, data });
  };

  return (
    <>
      <CommonModal
        show={show}
        setShow={setShow}
        type={modalData.type}
        data={modalData.data}
      />

      <div>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr className="table-head">
                <th scope="col">#</th>
                <th scope="col">Full Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Trip Code</th>
                <th scope="col">Mongolian Reg. No.</th>
                <th scope="col">Status</th>
                <th scope="col align-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users?.length ? (
                users?.map((user, i) => {
                  return (
                    <tr className="align-middle">
                      <th scope="row">{i + 1}</th>
                      <td>{user?.firstName?.concat(' ', user?.lastName)}</td>
                      <td>{user?.email}</td>
                      <td>{user?.phone}</td>
                      <td>{user?.uniqueId}</td>
                      <td>{user?.mongolianRegNumber || 'N/A'}</td>
                      <td>
                        <Badge
                          className="h-50"
                          bg={user?.isDeactivated ? 'danger' : 'success'}
                        >
                          {user?.isDeactivated ? 'Deactivated' : 'Active'}
                        </Badge>
                      </td>
                      <td>
                        <div className="d-flex ">
                          <Link to={`/user/details?id=${user?._id}`}>
                            <div className="d-flex p-1 align-items-center justify-content-center icon-box blue">
                              <FaEye className="action-icon" />
                            </div>
                          </Link>
                          <div
                            onClick={() => handleModal('deactivateUser', user)}
                            className="d-flex p-1 align-items-center justify-content-center icon-box yellow"
                          >
                            <ImUserMinus className="action-icon" />
                          </div>
                          <div
                            onClick={() => handleModal('deleteUser', user?._id)}
                            className="d-flex p-1 align-items-center justify-content-center icon-box red"
                          >
                            <MdDelete className="action-icon" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td>No entries yet</td>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
