import { useDispatch } from 'react-redux';
import {
  setLimit,
  setTotalPages,
  setTotalResults,
} from '../app/slices/pagination.slice';
import { useEffect } from 'react';
const usePageChange = (paginate) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (paginate) {
      dispatch(setLimit(paginate.limit));
      dispatch(setTotalResults(paginate.totalResults));
      dispatch(setTotalPages(paginate.totalPages));
    }
  }, [paginate]);
};

export default usePageChange;
