import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalType: null,
};

const headerButtonSlice = createSlice({
  name: 'headerButton',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state.modalType = payload;
    },
  },
});

export const { openModal } = headerButtonSlice.actions;
export default headerButtonSlice.reducer;
