const apiMiddleware = (store) => (next) => (action) => {
  if (action.type.endsWith('/rejected')) {
    if (action.payload.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
  }
  return next(action);
};

export default apiMiddleware;
