import React, { useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from '../../app/slices/pagination.slice';
import { useLocation } from 'react-router-dom';

const SearchInput = () => {
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.paginate);
  const { pathname } = useLocation();

  const [searchText, setSearchText] = useState('');

  const handleSubmit = () => {
    dispatch(setSearch(searchText));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleSubmit();
  };

  const handleChange = (e) => setSearchText(e.target.value);

  useEffect(() => {
    setSearchText('');
  }, [pathname]);

  return (
    <div className="search-input-div">
      <input
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        className="search-input custom-input"
        type="text"
        value={searchText}
      />
      {/* <IoIosSearch className="search-icon" /> */}
      <IoSearch onClick={handleSubmit} className="search-icon" />
    </div>
  );
};

export default SearchInput;
