import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetBookingDetailsQuery } from '../../app/services/booking.service';
import moment from 'moment';

const BookingDetails = () => {
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const { data } = useGetBookingDetailsQuery(id);

  const bookingDetails = data?.data;

  const user = bookingDetails?.userId;
  const driver = bookingDetails?.driverId;
  const trip = bookingDetails?.tripId;

  console.log(bookingDetails);

  return (
    <div className="container-fluid pt-3">
      <div className="row">
        <div className="col-md-12 d-flex justify-content-between">
          <div className="d-flex custom-card">
            <p className="label-details">Trip Id:</p> <p>#{trip?.tripNo}</p>
          </div>
          <div className="d-flex custom-card">
            <div className="d-flex mr-10">
              <p className="label-details">Date:</p>
              <p>{moment(trip?.tripDate).format('MMM DD YYYY')}</p>
            </div>
            <div className="d-flex">
              <p className="label-details">Time:</p>
              <p>{moment(trip?.departureTime).format('hh:mm a')}</p>
            </div>
          </div>
        </div>
      </div>
      <h6 className="mt-5">User Details</h6>
      <div className="row custom-card">
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">User Name:</p>
            <p>{user?.firstName?.concat(' ', user?.lastName)}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Phone Number:</p> <p>{user?.phone}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Mongolian Reg No:</p>
            <p>{user?.mongolianRegNumber || 'N/A'}</p>
          </div>
        </div>
      </div>
      <h6 className="mt-5">Driver Details</h6>
      <div className="row custom-card align-middle">
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Driver Name:</p>
            <p>{driver?.firstName?.concat(' ', driver?.lastName)}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Phone Number:</p>
            <p>{driver?.phone}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Mongolian Reg No:</p>
            <p>{driver?.mongolianRegNumber || 'N/A'}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Car Model:</p>
            <p>{trip?.car?.carModel}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Car Type:</p>
            <p>{trip?.carCategory?.carType}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Car Plate Number:</p>
            <p>{trip?.car?.carPlateNumber}</p>
          </div>
        </div>
      </div>
      <h6 className="mt-5">Trip/Booking Details</h6>
      <div className="row custom-card align-middle">
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Booked At:</p>
            <p>{moment(bookingDetails?.createdAt).format('DD/MM/YYYY')}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Total Price:</p>
            <p>{bookingDetails?.totalPrice}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">Price/Seat:</p>
            <p>{bookingDetails?.pricePerPassenger}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">No. of Passengers:</p>
            <p>{bookingDetails?.noOfSeats}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">PickUp:</p>
            <p>{trip?.pickUpLocation}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex">
            <p className="label-details">DropOff:</p>
            <p>{trip?.dropOffLocation}</p>
          </div>
        </div>
      </div>
      {bookingDetails?.passengers?.length ? (
        <>
          <h6 className="mt-5">Passengers Details</h6>
          <div className="row custom-card align-middle">
            {bookingDetails?.passengers?.map((pass) => (
              <>
                <div className="col-md-4">
                  <div className="d-flex">
                    <p className="label-details">Passenger Name:</p>
                    <p>{pass?.name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex">
                    <p className="label-details">Mongolian Reg No:</p>
                    <p>{pass?.mongolianRegNumber || 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  {/* <div className="d-flex">
                    <p className="label-details">Mongolian Reg No:</p>
                    <p>{pass?.mongolianRegNumber || "N/A"}</p>
                  </div> */}
                </div>
              </>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default BookingDetails;
