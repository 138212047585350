import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api_url } from '../../constants/envVars';

export const bookingApi = createApi({
  reducerPath: 'booking',
  baseQuery: fetchBaseQuery({
    baseUrl: api_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getBookings: builder.query({
      query: ({ page, limit, type, search }) => ({
        url: `/bookings/${type}?page=${page}&limit=${limit}&search=${search}`,
      }),
    }),
    getBookingDetails: builder.query({
      query: (id) => ({ url: `booking/${id}` }),
    }),
  }),
});

export const { useGetBookingsQuery, useGetBookingDetailsQuery } = bookingApi;
