import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useGetTransactionsQuery } from '../app/services/transaction.service';
import AccountDetailsCard from '../subpages/transactions/AccountDetailsCard';
import TransactionsTable from '../subpages/transactions/TransactionsTable';
import { useSelector } from 'react-redux';
import usePageChange from '../hooks/usePageChange';

const Transactions = () => {
  const { page, limit } = useSelector((state) => state.paginate);
  const today = dayjs();
  const aWeekBack = dayjs().subtract(7, 'days');

  const [dateRange, setDateRange] = useState([aWeekBack, today]);

  const convertDateRangeToObject = (dateRangeArr) => ({
    from: dateRangeArr[0].format('YYYYMMDD'),
    to: dateRangeArr[1].format('YYYYMMDD'),
  });

  const { data } = useGetTransactionsQuery({
    page,
    limit,
    ...convertDateRangeToObject(dateRange),
  });

  const transactionData = data?.data?.result;
  const paginate = data?.data?.paginate;

  usePageChange(paginate);

  return (
    <>
      {transactionData && (
        <>
          <AccountDetailsCard
            data={transactionData}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <div className="mt-3 h-385">
            <TransactionsTable transactions={transactionData.transactions} />
          </div>
        </>
      )}
    </>
  );
};

export default Transactions;
