import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from '../../constants/envVars.js';

export const transactionApi = createApi({
  reducerPath: 'transaction',
  baseQuery: fetchBaseQuery({
    baseUrl: base_url + '/api/v1/admin',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),

  endpoints: ({ query, mutation }) => ({
    getTransactions: query({
      query: ({ from, to, page, limit }) => ({
        url: `/bank/accounts/123/statement?from=${from}&to=${to}&page=${page}&limit=${limit}`,
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    getStartPercent: query({
      query: () => ({
        url: `/web/bank-transaction`,
      }),
    }),

    updateStartPercent: mutation({
      query: (data) => ({
        url: '/web/bank-transaction',
        method: 'PUT',
        body: data,
      }),
    }),
  }),
});

export const { useGetTransactionsQuery, useUpdateStartPercentMutation, useGetStartPercentQuery } = transactionApi;
