import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Layout from '../components/layout.js/Layout';
import UserManagement from '../pages/UserManagement';
import DriverManagement from '../pages/DriverManagement';
import Support from '../pages/Support';
import { Toaster } from 'react-hot-toast';
import StaticContent from '../pages/StaticContent';
import Bookings from '../pages/Bookings';
import PrivateRoute from './PrivateRoute';
import DriverDetails from '../subpages/driver/DriverDetails';
import Transactions from '../pages/Transactions';
import BookingDetails from '../subpages/bookings/BookingDetails';
import BankSetting from '../pages/BankSetting';
import ManageLocations from '../pages/ManageLocations';
import AddLocation from '../subpages/locations/AddLocation';
import ManageRoutes from '../pages/ManageRoutes';
import AddRoute from '../subpages/route/AddRoute';
import PromotionalBanners from '../pages/PromotionalBanners';
import BookingsAndTrips from '../pages/BookingsAndTrips';
import UserDetails from '../subpages/user/UserDetails';
import TripDetails from '../subpages/trips/TripDetails';
import PublicPrivacyPolicy from '../pages/PublicPrivacyPolicy';
import PublicSupport from '../pages/PublicSupport';

const PageRoutes = () => {
  const nonAuthPages = [
    { path: '/', element: <Login /> },
    { path: '/privacy-policy', element: <PublicPrivacyPolicy /> },
    { path: '/support', element: <PublicSupport /> },
  ];

  const pageElArr = [
    { path: '/dashboard', element: <Dashboard /> },
    { path: '/users', element: <UserManagement /> },
    { path: '/user/details', element: <UserDetails /> },
    { path: '/drivers', element: <DriverManagement /> },
    { path: '/locations', element: <ManageLocations /> },
    { path: '/add-location', element: <AddLocation /> },
    { path: '/routes', element: <ManageRoutes /> },
    { path: '/add-route', element: <AddRoute /> },
    { path: '/driver/details', element: <DriverDetails /> },
    { path: '/help-support', element: <Support /> },
    { path: ['/bookings', '/trips'], element: <BookingsAndTrips /> },
    { path: '/trip/details', element: <TripDetails /> },
    { path: '/booking/details', element: <BookingDetails /> },
    { path: '/transactions', element: <Transactions /> },
    { path: '/bank', element: <BankSetting /> },
    { path: '/promotional-banner', element: <PromotionalBanners /> },
    {
      path: ['/aboutus', '/privacy', '/terms', '/insurance'],
      element: <StaticContent />,
    },
    { path: '/*', element: <Dashboard /> },
  ];

  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          {nonAuthPages?.map(({ path, element }, i) => (
            <Route key={i} path={path} element={element} />
          ))}
          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>
              {pageElArr?.map(({ path, element }, i) =>
                Array.isArray(path) ? (
                  path.map((p) => (
                    <Route key={`${i}-${p}`} path={p} element={element} />
                  ))
                ) : (
                  <Route key={i} path={path} element={element} />
                ),
              )}
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default PageRoutes;
