import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MdOutlineReply } from 'react-icons/md';
import {
  useChangeSupportStatusMutation,
  useFetchHelplineNumberQuery,
  useGetSupportsQuery,
  useUpdateHelplineNumberMutation,
} from '../app/services/support.service';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import useToast from '../hooks/useToast';
import SupportReply from '../components/modals/SupportReply';
import usePageChange from '../hooks/usePageChange';
import SubmitButton from '../components/buttons/SubmitButton';
import toast from 'react-hot-toast';

const Support = () => {
  const { page, limit } = useSelector((state) => state.paginate);
  const makeToast = useToast();
  const { data } = useGetSupportsQuery({ page, limit });
  const { data: helpNumber } = useFetchHelplineNumberQuery();
  const [changeSupportStatus] = useChangeSupportStatusMutation();
  const [show, setShow] = useState(false);
  const [modalData, setData] = useState();
  const [helplineNumber, setHelplineNumber] = useState();

  useEffect(() => {
    if (helpNumber) setHelplineNumber(helpNumber?.data?.helplineNumber);
  }, [helpNumber]);

  const supports = data?.data?.result;
  const paginate = data?.data?.paginate;

  usePageChange(paginate);

  const handleSupportStatusChange = async (selectedKey, supportId) => {
    const { data } = await changeSupportStatus({
      status: selectedKey,
      supportId,
    });
    makeToast(data);
  };

  const supportStatusObj = {
    pending: 'warning',
    progress: 'info',
    resolved: 'success',
  };

  const statusTitle = {
    pending: 'Pending',
    progress: 'In-Progress',
    resolved: 'Resolved',
  };

  const handleModal = (supportObj) => {
    setData(supportObj);
    setShow(true);
  };

  const [updateMobileNumber] = useUpdateHelplineNumberMutation();

  const handleUpdate = async () => {
    if (!helplineNumber) return toast.error('Helpline Number cannot be empty');

    const response = await updateMobileNumber({ helplineNumber });
    if (response?.error) makeToast(response?.error?.data);
    if (response?.data) makeToast(response?.data);
  };

  return (
    <>
      <SupportReply show={show} setShow={setShow} data={modalData} />

      <div className="mb-3">
        <label>Helpline Number:</label>
        <input
          type="number"
          className="form-control custom-input helpline-number mx-2"
          value={helplineNumber}
          onChange={(e) => setHelplineNumber(e.target.value)}
        />
        <button onClick={handleUpdate} className="helpline-btn">
          Update
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="table-head">
              <th scope="col">#</th>
              <th scope="col">Trip Id</th>
              <th scope="col">Full Name</th>
              <th scope="col">Subject</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {supports?.length ? (
              supports?.map((support, i) => {
                return (
                  <tr key={i} className="align-middle">
                    <th scope="row">{i + 1}</th>
                    <td>{'#' + support?.tripId?.tripNo}</td>
                    <td>
                      {support?.userId?.firstName?.concat(
                        ' ',
                        support?.userId?.lastName,
                      )}
                    </td>
                    <td>{support?.subject}</td>
                    {/* <td>
                      <Badge
                        className="h-50"
                        bg={driver?.isDeactivated ? "danger" : "success"}
                      >
                        {driver?.isDeactivated ? "Deactivated" : "Active"}
                      </Badge>
                    </td> */}
                    <td>
                      <DropdownButton
                        onSelect={(e) =>
                          handleSupportStatusChange(e, support?._id)
                        }
                        as={ButtonGroup}
                        key={supportStatusObj[support?.status]}
                        // id={`dropdown-variants-${variant}`}
                        variant={supportStatusObj[support?.status]}
                        title={statusTitle[support?.status]}
                      >
                        {Object.keys(statusTitle)
                          ?.filter(
                            (key) =>
                              statusTitle[key] !== statusTitle[support?.status],
                          )
                          ?.map((key) => (
                            <Dropdown.Item eventKey={key}>
                              {statusTitle[key]}
                            </Dropdown.Item>
                          ))}
                      </DropdownButton>
                      {/* <div className="d-flex ">
                        <div
                          onClick={() =>
                            handleModal("deactivateDriver", driver)
                          }
                          className="d-flex p-1 align-items-center justify-content-center icon-box yellow"
                        >
                          <ImUserMinus className="action-icon" />
                        </div>
                        <div
                          onClick={() =>
                            handleModal("deleteDriver", driver?._id)
                          }
                          className="d-flex p-1 align-items-center justify-content-center icon-box red"
                        >
                          <MdDelete className="action-icon" />
                        </div>
                      </div> */}
                    </td>
                    <td>
                      <div
                        onClick={() => handleModal(support)}
                        className="d-flex p-1 align-items-center justify-content-center icon-box green"
                      >
                        <MdOutlineReply className="action-icon" />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td>No entries yet</td>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Support;
