import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useGetDriverDetailsQuery } from '../../app/services/user.service';
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DriverProfile from './DriverProfile';
import DriverCars from './DriverCars';
const DriverDetails = () => {
  const [queryParams] = useSearchParams();
  const driverId = queryParams.get('id');

  const { data } = useGetDriverDetailsQuery(driverId);
  const driverDetails = data?.data?.driver;
  const cars = data?.data?.cars;
  const [key, setKey] = useState('profile');
  return (
    <Tabs
      id="fill-tab-example"
      fill
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3 driver-details-tab"
    >
      <Tab eventKey="profile" title="Profile">
        <DriverProfile data={driverDetails} />
      </Tab>
      <Tab eventKey="cars" title="Cars">
        <DriverCars cars={cars} />
      </Tab>
    </Tabs>
  );
};

export default DriverDetails;
