import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api_url } from '../../constants/envVars';

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: fetchBaseQuery({
    baseUrl: api_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),
  tagTypes: ['User', 'Driver', 'DriverDetails', 'UserDetails'],
  endpoints: (builder) => ({
    //##################################### USERS ##########################################################

    getUsers: builder.query({
      query: ({ page, limit, search }) => ({
        url: `/get-users?page=${page}&limit=${limit}&search=${search}`,
      }),
      providesTags: ['User'],
    }),
    getUserDetails: builder.query({
      query: (id) => `/user/${id}`,
      providesTags: ['UserDetails'],
    }),
    updateUserDetails: builder.mutation({
      query: (data) => ({
        url: '/user',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['UserDetails', 'User'],
    }),
    deactivateUser: builder.mutation({
      query: ({ type, id }) => ({
        url: `/user/${type}/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `/user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),

    //##################################### DRIVERS ##########################################################

    getDrivers: builder.query({
      query: ({ page, limit, search }) => ({
        url: `/get-drivers?page=${page}&limit=${limit}&search=${search}`,
      }),
      providesTags: ['Driver'],
    }),
    changeVerificationStatus: builder.mutation({
      query: ({ type, id }) => ({
        url: `/user/driver-verify/${type}/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Driver'],
    }),
    deactivateDriver: builder.mutation({
      query: ({ type, id }) => ({
        url: `/user/${type}/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Driver'],
    }),
    deleteDriver: builder.mutation({
      query: ({ id }) => ({
        url: `/user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Driver'],
    }),

    //##################################### DRIVER DETAILS##########################################################

    getDriverDetails: builder.query({
      query: (id) => ({
        url: `/driver/${id}`,
      }),
      providesTags: ['DriverDetails'],
    }),

    updateDriver: builder.mutation({
      query: (formdata) => ({
        url: '/driver/details',
        method: 'PUT',
        body: formdata,
      }),
      invalidatesTags: ['DriverDetails', 'Driver'],
    }),

    updateCars: builder.mutation({
      query: (formdata) => ({
        url: '/driver/cars',
        method: 'PUT',
        body: formdata,
      }),
    }),
    fetchDriverCars: builder.query({
      query: (driverId) => `/driver/${driverId}/cars`,
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useGetDriversQuery,
  useGetDriverDetailsQuery,
  useDeactivateDriverMutation,
  useDeleteDriverMutation,
  useChangeVerificationStatusMutation,
  useUpdateDriverMutation,
  useUpdateCarsMutation,
  useFetchDriverCarsQuery,
} = userApi;
