import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { modalData } from './modalData';
import { useModalFunctions } from '../../hooks/useModalFunctions';

const CommonModal = ({ show, setShow, type, data, contentParams = {} }) => {
  const [content, setContent] = useState({ title: '', body: '' });
  const handleModalAction = useModalFunctions();
  const handleClose = () => {
    setShow(false);
  };

  const handleAffirmation = () => {
    handleModalAction(type, data);
    handleClose();
  };

  useEffect(() => {
    if (type) {
      setContent(() => modalData[type](contentParams));
    }
  }, [type, contentParams]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {content.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content.body}</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-between w-150">
            <Button
              variant="primary"
              className="yes-btn w-60"
              onClick={handleAffirmation}
            >
              Yes
            </Button>
            <Button variant="secondary" className="w-60" onClick={handleClose}>
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonModal;
