import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from '../../constants/envVars';

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({ baseUrl: base_url + '/api/v1' }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: '/admin/auth/login',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation } = loginApi;
