import React from 'react';
import { useGetStaticContentQuery } from '../app/services/static.service';

const PublicPrivacyPolicy = () => {
  document.title = 'Privacy Policy';
  const { data } = useGetStaticContentQuery('privacy');

  return (
    <div>
      <div
        className="privacy-policy ql-editor"
        dangerouslySetInnerHTML={{ __html: data?.data?.content || <></> }}
      />
    </div>
  );
};

export default PublicPrivacyPolicy;
