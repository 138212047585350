import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api_url } from '../../constants/envVars';

export const tripApi = createApi({
  reducerPath: 'trip',
  baseQuery: fetchBaseQuery({
    baseUrl: api_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),
  tagTypes: ['Trips', 'TripDetails'],
  endpoints: ({ query, mutation }) => ({
    getTrips: query({
      query: ({ page, limit, type, search }) => ({
        url: `/trips/${type}?page=${page}&limit=${limit}&search=${search}`,
      }),
      providesTags: ['Trips'],
    }),
    getTripDetails: query({
      query: (id) => `/trip/details/${id}`,
      providesTags: ['TripDetails'],
    }),
    changeTripStatus: mutation({
      query: (data) => ({
        url: '/trips/status',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Trips'],
    }),

    updateTrip: mutation({
      query: (data) => ({
        url: '/trip',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Trips', 'TripDetails'],
    }),

    // getBookingDetails: query({
    //   query: (id) => ({ url: `booking/${id}` }),
    // }),
  }),
});

export const {
  useGetTripsQuery,
  useGetTripDetailsQuery,
  useChangeTripStatusMutation,
  useUpdateTripMutation,
} = tripApi;
