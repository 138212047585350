import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { FloatLabel } from 'primereact/floatlabel';

import {
  useUpdateStartPercentMutation,
  useGetStartPercentQuery,
} from '../app/services/transaction.service';
import toast from 'react-hot-toast';

const BankSetting = () => {
  const [updateStartPercent] = useUpdateStartPercentMutation();

  const { data: startPercentResponse } = useGetStartPercentQuery();
  const [startPercent, setPercent] = useState(null);

  useEffect(() => {
    if (startPercentResponse?.data?.startPercent !== undefined) {
      setPercent(startPercentResponse.data.startPercent);
    }
  }, [startPercentResponse]);

  const handleValueChange = (e) => {
    setPercent(e.value); // InputNumber-ийн утгыг шинэчлэх
  };

  function onSubmit() {
    updateStartPercent({ startPercent }).then((response) => {
      toast.success(response?.data?.message);
    });
  }

  return <div className={'flex justify-content-between w-25rem align-items-center mt-4 ml-3'}>
    <FloatLabel >
      <InputNumber id={"firstPercent"} value={startPercent} className={'h-2rem'} onValueChange={handleValueChange} min={0} max={100} />
      <label htmlFor="firstPercent">Эхний шилжүүлэг %</label>
    </FloatLabel>
    <Button label="Хадгалах" className={'border-round h-2rem'} onClick={(e) => onSubmit()} severity="success" />
  </div>;
};

export default BankSetting;
