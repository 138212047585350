import { setLoading } from '../slices/loaderSlice';

const loadingMiddleware = (store) => (next) => (action) => {
  if (action.type.endsWith('/pending')) {
    store.dispatch(setLoading(true));
  } else if (
    action.type.endsWith('/fulfilled') ||
    action.type.endsWith('/rejected')
  ) {
    store.dispatch(setLoading(false));
  }
  return next(action);
};

export default loadingMiddleware;
