import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import InputField from '../components/inputs/InputField';
import SubmitButton from '../components/buttons/SubmitButton';
import { useLoginMutation } from '../app/services/auth.service';
import toast from 'react-hot-toast';

const Login = () => {
  const [adminLogin] = useLoginMutation();

  const handleSubmit = (data) => {
    adminLogin(data)
      .unwrap()
      .then((payload) => {
        if (payload?.status === 200) {
          toast.success(payload.message);
          localStorage.setItem('token', payload.data.token);
          window.location.href = '/dashboard';
        }
      })
      .catch((error) => {
        const errorMessage = error?.data?.message || 'An error occurred';
        toast.error(errorMessage);
      });
  };

  return (
    <div className="d-flex login-page align-items-center justify-content-center overflow-hidden px-3">
      <Card className="login-form mb-2 br-15">
        <Card.Body className="login-card br-15 d-flex flex-column justify-content-center align-items-center">
          <img
            className="pool-logo mt-2"
            src={require('../assets/images/pool_logo.png')}
          />

          <Card.Title className="mt-5">Sign In</Card.Title>

          <Formik
            initialValues={{ adminEmail: '', adminPassword: '' }}
            validate={(values) => {
              const errors = {};
              if (!values.adminEmail) {
                errors.adminEmail = 'Required*';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.adminEmail,
                )
              ) {
                errors.adminEmail = 'Invalid email address';
              }
              if (!values.adminPassword) {
                errors.adminPassword = 'Required*';
              }
              return errors;
            }}
            onSubmit={(values) =>
              handleSubmit({
                email: values.adminEmail,
                password: values.adminPassword,
              })
            }
          >
            {({ isSubmitting }) => (
              <Form className="p-3 row">
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <label>Email address</label>
                    <ErrorMessage
                      className="formik-errors"
                      name="adminEmail"
                      component="span"
                    />
                  </div>
                  <InputField
                    placeholder={'Enter email address'}
                    type="email"
                    name="adminEmail"
                  />
                </div>
                <div className="form-group mt-3">
                  <div className="d-flex justify-content-between">
                    <label>Password</label>
                    <ErrorMessage
                      className="formik-errors"
                      name="adminPassword"
                      component="span"
                    />
                  </div>
                  <div className="input-group">
                    <InputField
                      placeholder={'Enter password'}
                      type="password"
                      name="adminPassword"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <SubmitButton>Sign In</SubmitButton>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>

      {/*  */}
    </div>
  );
};

export default Login;
