import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
const TripDetailsModal = ({ show, setShow, data }) => {
  const navigate = useNavigate();
  const handleClose = () => setShow(false);

  const handleClick = (userId) => {
    const id = data?.bookings?.find(
      (booking) => booking?.userId === userId,
    )?._id;
    navigate(`/booking/details?id=${id}`);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Trip Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="mb-2">Trip Id : {data?.tripNo}</div>
            {data?.bookingusers?.map((user) => (
              <div
                onClick={() => handleClick(user?._id)}
                className="row mb-2 booking-user"
              >
                <div className="col-md-6">
                  <div className="d-flex">
                    <p className="label-details">Username:</p>
                    <p>{user?.firstName?.concat(' ', user?.lastName)}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex">
                    <p className="label-details">Phone:</p>
                    <p>{user?.phone}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-between w-150">
           <Button
              variant="primary"
              className="yes-btn w-60"
              onClick={handleAffirmation}
            >
              Yes
            </Button>
            <Button variant="secondary" className="w-60" onClick={handleClose}>
              No
            </Button>
          </div>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default TripDetailsModal;
