import moment from 'moment';
import { Badge } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { GoArrowRight } from 'react-icons/go';
import { useGetBookingsQuery } from '../../app/services/booking.service';
import { useSelector } from 'react-redux';
import usePageChange from '../../hooks/usePageChange';
const BookingsTable = () => {
  const { page, limit, search } = useSelector((state) => state.paginate);
  const { data } = useGetBookingsQuery({ page, limit, type: 'all', search });

  const bookings = data?.data?.result;
  const paginate = data?.data?.paginate;

  usePageChange(paginate);
  return (
    <>
      {bookings?.length ? (
        bookings?.map((booking, i) => {
          // i === 0 && console.log(booking);
          return (
            <tr key={i} className="align-middle">
              <th scope="row">{i + 1}</th>
              <td>{'#' + booking?.tripId?.tripNo}</td>
              <td>
                {`${moment(booking?.tripId?.tripDate).format(
                  'DD/MM/YY',
                )} (${moment(booking?.tripId?.departureTime).format(
                  'hh:mm a',
                )})`}
              </td>
              <td>{booking?.driverId?.phone}</td>
              <td>
                <span>
                  {booking?.tripId?.pickUpCity} <GoArrowRight />{' '}
                  {booking?.tripId?.dropOffCity}
                </span>
              </td>
              <td>{booking?.userId?.phone}</td>
              <td>{booking?.noOfSeats}</td>
              <td>{booking?.totalPrice}₮</td>
              <td>
                <Link to={`/booking/details?id=${booking?._id}`}>
                  <div className="d-flex p-1 align-items-center justify-content-center icon-box blue">
                    <FaEye className="action-icon" />
                  </div>
                </Link>
              </td>
            </tr>
          );
        })
      ) : (
        <td className="align-middle">No entries yet</td>
      )}
    </>
  );
};

export default BookingsTable;
