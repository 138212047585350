import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../paginate/Pagination';
import { openModal } from '../../app/slices/headerButtonSlice';
import { useDispatch } from 'react-redux';
import SearchInput from '../inputs/SearchInput';
import { CiExport } from 'react-icons/ci';
import axios from 'axios';
import { api_url } from '../../constants/envVars';

const ComponentFrame = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pagenames = {
    '/dashboard': 'Dashboard',
    '/users': 'User Management',
    '/drivers': 'Driver Management',
    '/user/details': 'User Details',
    '/driver/details': 'Driver Details',
    '/locations': 'Manage Locations',
    '/add-location': 'Add Location',
    '/routes': 'Routes',
    '/add-route': 'Add Routes',
    '/bookings': 'Bookings And Trips',
    '/trips': 'Bookings And Trips',
    '/booking/details': 'Booking Details',
    '/trip/details': 'Trip Details',
    '/transactions': 'Transactions',
    '/bank': 'Автомат шилжүүлгийн тохиргоо',
    '/help-support': 'Help and Support',
    '/aboutus': 'About Us',
    '/privacy': 'Privacy Policy',
    '/terms': 'Terms and Conditions',
    '/insurance': 'Insurance Agreement',
    '/promotional-banner': 'Promotional Banner',
  };

  const paginationPages = [
    '/users',
    '/drivers',
    '/help-support',
    '/bookings',
    '/trips',
    '/transactions',
    '/locations',
    '/routes',
  ];
  const exportPages = [
    '/users',
    '/drivers',
    '/bookings',
    '/trips',
    '/locations',
    '/routes',
  ];

  const buttonPages = ['/locations', '/routes'];

  const handleClick = () => {
    switch (pathname) {
      case '/locations':
        // dispatch(openModal("addLocation"));
        navigate('/add-location');
        break;
      case '/routes':
        // dispatch(openModal("addLocation"));
        navigate('/add-route');
        break;

      default:
        break;
    }
  };

  document.title = pagenames[pathname];

  const handleExport = () => {
    const type = pathname.slice(1);
    axios
      .get(`${api_url}/export/${type}`, {
        headers: { Authorization: localStorage.getItem('token') },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${type}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <div className="component-frame">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h3>{pagenames[pathname]}</h3>
          {exportPages?.includes(pathname) && (
            <CiExport onClick={handleExport} className="export-icon" />
          )}
        </div>

        <div className="d-flex">
          {buttonPages.includes(pathname) && (
            <button onClick={handleClick} className="header-btn">
              Add
            </button>
          )}
          {paginationPages.includes(pathname) && <SearchInput />}
        </div>
      </div>
      <div className="row">
        <div className="container">
          <div className="content-card">{children}</div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6 m-auto text-center">
          {paginationPages.includes(pathname) && <Pagination />}
        </div>
      </div>
    </div>
  );
};

export default ComponentFrame;
