import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api_url } from '../../constants/envVars';

export const supportApi = createApi({
  reducerPath: 'support',
  baseQuery: fetchBaseQuery({
    baseUrl: api_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),
  tagTypes: ['Support', 'HelplineNumber'],
  endpoints: (builder) => ({
    getSupports: builder.query({
      query: ({ page, limit, search }) => ({
        url: `/support?page=${page}&limit=${limit}&search=${search}`,
      }),
      providesTags: ['Support'],
    }),

    supportReply: builder.mutation({
      query: (data) => ({
        url: '/support/reply',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Support'],
    }),
    changeSupportStatus: builder.mutation({
      query: (data) => ({
        url: '/support',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Support'],
    }),

    fetchHelplineNumber: builder.query({
      query: () => ({
        url: '/helpline-number',
      }),
      providesTags: ['HelplineNumber'],
    }),
    updateHelplineNumber: builder.mutation({
      query: (data) => ({
        url: '/helpline-number',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['HelplineNumber'],
    }),
  }),
});

export const {
  useGetSupportsQuery,
  useChangeSupportStatusMutation,
  useSupportReplyMutation,
  useUpdateHelplineNumberMutation,
  useFetchHelplineNumberQuery,
} = supportApi;
