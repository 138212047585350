import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarvisible: false,
};

const responisveSlice = createSlice({
  name: 'responisve',
  initialState,
  reducers: {
    changeSidebarVisibility: (state) => {
      state.sidebarvisible = !state.sidebarvisible;
    },
  },
});

export const { changeSidebarVisibility } = responisveSlice.actions;
export default responisveSlice.reducer;
