import React from 'react';

const PublicSupport = () => {
  return (
    <div className="public-support">
      <h4>Overview</h4>
      <p>
        The Pool App includes a dedicated support section designed to facilitate
        efficient communication between users and the pool team. This feature
        ensures that drivers can quickly request assistance and raise any
        queries they may have regarding their work, the app, or the towing
        process.
      </p>
      <p>
        App users can find the Heal and Support section in the setting menu,
        right from the home screen of the application. The Help and Support
        section allows the user to submit support tickets that the Pool team
        will actively monitors and provides resolutions for.
      </p>
    </div>
  );
};

export default PublicSupport;
