import { FaUsers } from 'react-icons/fa6';
import { GiSteeringWheel } from 'react-icons/gi';
import { BsBookmarkCheckFill } from 'react-icons/bs';
import { useGetDashboardQuery } from '../app/services/dashboard.service';
import { io } from 'socket.io-client';
import { useEffect, useState } from 'react';

const Dashboard = () => {
  const { data, isLoading } = useGetDashboardQuery();
  const dashboard = data?.data;
  const [socket, setSocket] = useState(null);

  console.log('isLoading', isLoading);

  // useEffect(() => {
  //   const newSocket = io("https://pool-backend.alcax.com", {
  //     path: "/socket.io",
  //     forceNew: true,
  //     reconnectionAttempts: 3,
  //     timeout: 2000,
  //   });
  //   setSocket(newSocket);

  //   return () => {
  //     if (newSocket) {
  //       newSocket.disconnect();
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (socket) {
  //     socket.on("connect", () => {
  //       console.log("Connected to socket:", socket.id);
  //     });
  //     socket.emit("join_room", "66599e7536ff6e4478db75a9");
  //     socket.on("recv_message", (data) => console.log(data));

  //     // Additional event listeners can be added here

  //     // Cleanup event listeners on unmount or socket change
  //     return () => {
  //       if (socket) {
  //         socket.off("connect");
  //         // Remove other event listeners here if any
  //       }
  //     };
  //   }
  // }, [socket]);

  const firstRowData = [
    { title: 'Total Users', key: 'users', icon: FaUsers },
    { title: 'Total Drivers', key: 'drivers', icon: GiSteeringWheel },
    {
      title: 'Total Bookings',
      key: 'totalBookings',
      icon: BsBookmarkCheckFill,
    },
  ];

  return (
    <div className="container-fluid p-4">
      <div className="row">
        {firstRowData?.map((rowData, i) => (
          <div key={i} className="col-md-4">
            <div className="dashboard-card">
              <div className="d-flex justify-content-between">
                <h4>{rowData.title}</h4>
                <rowData.icon className="fs-55" />
              </div>
              <h3>{dashboard?.[rowData.key]}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
